import React, { useEffect, useState } from 'react';
import calc from '../Assets/images/calc.png'
import logoicon from '../Assets/images/logoicon.png'
import { ONEDAYINSECONDS } from '../Config/env';
import { getAccount } from '../Hooks/useAccounts';
import { approveContract, checkIsApproved, Claim, getUser, getUserBalance, getUserReward, UseTokenInfo } from '../Hooks/useStake';
import UnlockModal from './Modal/UnlockWalletMadal';
import WithdrawModal from './Modal/WithdrawModal';


const LockedStackCont = (props) => {
    const[unlock, setUnlock] = useState(false)
    const[Withdraw, setWithdraw] = useState(false)
    const [pooldays, setpoolDays] = useState('')
    const [status, setStatus] = useState(false)
    const [accountInfo, setAccountInfo] = useState(getAccount());
    const [userInfo,setUserInfo] = useState({});
    const [userstatedate,setuserstatedate]  = useState("-")
    const [tokenInfo, setTokenInfo] = useState(null);
    const [userStakedAmount, setUserStakedAmount] = useState(0);
    const [pending,setPending] = useState(0)
    const [yieldValue, setyield] =useState("0")
    const [isApproved , setIsApproved] = useState(false); 
    const [userTokenBalance, setUserTokenBalance] = useState("");
    
    
    useEffect(()=>{
        // showloader()
       
       
        if(accountInfo){
            getData()
           
            if(props.datas.lpToken && props.datas.lpToken){
                approveFunction(props.datas.lpToken);
                } 
                getUserTokenBalance();
               
         }
    },[accountInfo])


    function showloader() {
        document.getElementById("loader_div").style.display = "block";
    }

    function hideloader()
    {
        document.getElementById("loader_div").style.display = "none";
    } 
    const getData = async() => {
       
        var poolday = ((parseInt(props.datas.lockedPeriod)/ONEDAYINSECONDS))
        setpoolDays(String(poolday).substring(0,3));
        if(poolday <= 0){
            setStatus(true)
        }
        

        var getuser =  await getUser(props.pid,accountInfo)
        const datee = new Date(parseInt(getuser.userLockedPeriod)*1000);
          const unstakeDate = datee.toLocaleDateString();
          if(getuser.userLockedPeriod > 0)
          setuserstatedate(unstakeDate)
        //   hideloader()

          var TokenInfo = await UseTokenInfo(props.datas.lpToken)
              setTokenInfo(TokenInfo);
              setUserStakedAmount(getuser.amount);

              var UserReward = await getUserReward(props.pid,accountInfo);
              const dataF = (parseInt(UserReward) + parseInt(getuser.pendingRewards)) /10**parseInt(TokenInfo?.decimals)
              setPending(dataF.toFixed(5))
     
              
const a = "50%"
const b ="2%"
const c = "5%"
const d = "0.05%"
const e ="2%"
const f = "5%"

if(props?.pid === 0)
{
  setyield(a)
 
}if(props?.pid === 1){
  setyield(b)
 
}if(props?.pid === 2){
  setyield(c)
 
}
if(props?.pid === 3){
  setyield(d)
 
}
if(props?.pid === 4){
  setyield(e)
  
}
if(props?.pid === 5){
  setyield(f)
 
}}


const claim = async() => {
  await  Claim(getAccount(),props.pid)
}

const approveFunction = async (data) => {
    if(data){
    const approval = await checkIsApproved(accountInfo,data?data:tokenInfo.addres);
       setIsApproved(approval);
    }
}

const approve = async () => {
    if(accountInfo){
       
        await approveContract(accountInfo,props.datas.lpToken);
        setIsApproved(true);
    }
   
}

const getUserTokenBalance = async () => {

    if(props.datas.lpToken){
             var userbalance = await getUserBalance(props.datas.lpToken,accountInfo)
            
             setUserTokenBalance(userbalance);
         }
}
    return(
        <div className='col-lg-4 col-md-6 lockedstackcont'>
            {unlock && <UnlockModal data={tokenInfo} userTokenBalance={userTokenBalance} pid={props.pid} onDismiss={() => setUnlock(false)} />}
            {Withdraw && <WithdrawModal data={tokenInfo}  userStakedAmount={userStakedAmount} unstakeDate={userstatedate} pid={props.pid} onDismiss={() => setWithdraw(false)} />}
                        <div className='launchbox p-sm-4 p-3 br-14 mt-sm-5 mt-3'>
                            <div className='d-flex align-items-center jc-between'>
                                <div className='logocont'><img src={logoicon} /> 
                                {/* <span className='gruntitle'>GRUN</span> */}
                                <span>{tokenInfo && tokenInfo?.symbol}</span>
                                </div>  
                                <div>
                                <button className='ovalbtn'>{pooldays || 0} Days</button>
                                {/* <button className='ovalbtn'>180 Days</button> */}
                                </div>
                            </div>
                            
                            <div className='d-flex jc-between align-items-center mt-4'>
                                <p className='mb-0 unstake'>Unstake available after : {userstatedate ? userstatedate : userstatedate}</p>
                            <button className='chocobtn livebtn'>{status ? "Ended" : "Live" }</button>
                            </div>
                            <div className='bordbox p-4 br-14 my-3'>
                                <div className='d-flex jc-between gap-2'>
                                    <div>
                                    <div>
                                        <p className='mb-1 textclr'>Staked</p>
                                        <p className='light-p'>{(userStakedAmount && (parseInt(userStakedAmount)/10**parseInt(tokenInfo && tokenInfo?.decimals)).toFixed(2)) || 0}</p>
                                    </div>
                                    <div>
                                        <p className='mb-1 textclr'>Earned  {tokenInfo && tokenInfo?.symbol}
                                        {/* {tokenInfo && tokenInfo?.symbol} */}
                                        :
                                        </p>
                                        <p className='light-p'>{pending || 0}</p>
                                    </div>
                                    </div>
                                    
                                    <div>
                                        <p className='mb-1 lightapr'>APR</p>
                                        <p className='light-p'><img src={calc} className="calcicon"/> {((props.datas.MetaPerBlock/10** Number(18))*Number(pooldays)).toFixed(2)} %</p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-5 mb-4'> 
                            {(accountInfo == "" || accountInfo == null)?
                            <button className='btn getstarted d-none'>Unlock Wallet</button> :
                            (isApproved ) ?
                            <div className='d-flex justify-content-between flex-1 gap-sm-4 gap-2'>
                                <button className='btn getstarted'  onClick={claim}>Claim</button>
                               <div className='d-flex justify-content-end gap-2'> <button className='btn getstarted small' onClick={() => setUnlock(true)}>+</button>
                                <button className='btn getstarted small' onClick={() => setWithdraw(true)}>-</button></div>
                            </div> :<button className='btn getstarted'  onClick={() => approve()}>Approve</button>}
                            
                            </div>
                        </div>
                    </div>
    )
}

export default LockedStackCont