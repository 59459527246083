import React, { useState,useEffect } from 'react';
import {Button, Modal, InputGroup, Form} from 'react-bootstrap'
import { UseAdminLogin } from '../../Hooks/UsePostBackend';



const LoginModal = (props) =>{
    const[islogin, setIslogin] = useState(true)
    const[pwdshow, setpwdshow] = useState(false)
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const [passwordeye, setpasswordeye] = useState(false)
  



    const AdminLogin =async(data) =>{
      await UseAdminLogin(data)
      // await sleep(1000)
     
      
  }
  const onAdminLoginSubmit = async () => {
  
     const AdminLoginData = {
          
          email:Email,
          password: Password,
       };
      
       await AdminLogin(AdminLoginData)
      
  };
  
  
  const showNewPassword = (e) => {
  
      var e = document.getElementById("Password");
      if (e.type === "password") {
        setpasswordeye(true)
          e.type = "text";
      } else {
        setpasswordeye(false)
          e.type = "password";
      }
  };
    return(
        <>
         <Modal show={islogin} centered size="md">
            <Modal.Header className='pb-0'>
            <h5 className='themeclr'>Login</h5>
            <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span  className='fa fa-times'></span></button>
            </Modal.Header>
            <Modal.Body className='py-4 modal_forn_new'>
              <div >
                <label>Email</label>
              <InputGroup className="mb-3">
                <Form.Control aria-label="User Name" 
                type='text'
                value={Email} 
                required
                onChange={(e) => { setEmail(e.target.value) }} 
                placeholder='Enter Email'  
               />
            </InputGroup>

            <label>Password</label>
              <InputGroup className="mb-3">
                <Form.Control aria-label="Password" 
                type='password'
                required
                id="Password"
                onChange={(e) => { setPassword(e.target.value) }} 
                placeholder='******'/>
                <InputGroup.Text >
                {!passwordeye ?
                                    <span   className="fa fa-eye-slash pwd_hover_eye" onClick={showNewPassword}></span> :
                                    <span   className="fa fa-eye pwd_hover_eye" onClick={showNewPassword}></span>
                }
                                    </InputGroup.Text>
            </InputGroup>

            <div className='text-center'>
                <button className='btn getstarted' onClick={()=> onAdminLoginSubmit()}>Login</button>
            </div>
            <div className="text-center a_with_text_modal">
                <p className='text-center'>Don't have an account? <a href="javascript:void(0)" className="modal_redirect_a" onClick={()=>{props.onDismiss();props.onOpenregister()}}>Register</a></p>
            </div>

            <div className="text-center a_with_text_modal">
                <p className='text-center'>Forgot Password? <a href="javascript:void(0)" className="modal_redirect_a" onClick={()=>{props.onDismiss();props.onOpenforgot()}}>Set Password</a></p>
            </div>
              </div>
               
            </Modal.Body>
         
        </Modal>
        </>
    )
}

export default LoginModal