import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import logo from "../Assets/images/logo.png"
import { NavLink } from 'react-router-dom';
import Connect from './Modal/ConnectModal';
import LoginModal from './Modal/LoginModal';
import RegisterModal from './Modal/RegisterModal';
import ForgotModal from './Modal/ForgotModal';

import { getAccount } from '../Hooks/useAccounts';
import { UseProvider } from '../Hooks/UseWeb3';
import { style, iconTheme, position } from '../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';
import { getSettingsData } from '../Hooks/UseBackend';

const Header = () => {
    const [connect, setConnect] = useState(false)
    const[islogin, setIslogin] = useState(false)
    const[isregister, setIsregister] = useState(false)
    const[isforgot, setIsforgot] = useState(false)


    const [accountInfo, setAccountInfo] = useState("");
    const [settingdata, setsettingdata] = useState([]);

    const [theme, settheme] = useState(true);

    useEffect(() => {
        // localStorage.setItem("theme1","LT")
        setAccountInfo(getAccount());
        getData()
       ;
        themechange(localStorage.getItem("theme1"))
    }, []);

    const themechange = (data) => {
        
        if (data == "LT" || data == undefined) {
            localStorage.setItem("theme1","LT")
            document.getElementById("root").classList.add("light_theme")
            document.getElementById("root").classList.remove("dark_theme");
      
            // this.setState({theme:false})
            // this.state.theme === false
            settheme(true)
            

        } else {
            localStorage.setItem("theme1","DT") 
            document.getElementById("root").classList.remove("light_theme")
            document.getElementById("root").classList.add("dark_theme")
          
            // this.state.theme === true
            // this.setState({theme:true})
            settheme(false)
        }
    }

    const logOut = async () => {
        setAccountInfo("");
        localStorage.removeItem("accountInfo");
        sessionStorage.removeItem("username")
        if (localStorage.getItem("walletconnect") != null) {
            const provider = await UseProvider();
            await provider.disconnect()
        }


        toast.success("Wallet Disconnected successfully", {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
        setTimeout(() => {

            window.location.reload()
        }, 1000);
    }



    const getData = async () => {
        var settingdata = await getSettingsData();
        setsettingdata(settingdata);
    }

    return (
        <div className='header'>
            {connect && <Connect onDismiss={() => setConnect(false)} />}
            {islogin && <LoginModal onDismiss={() => setIslogin(false)} onOpenregister={()=>setIsregister(true)} onOpenforgot={()=>setIsforgot(true)} />}
            {isregister && <RegisterModal onDismiss={() => setIsregister(false)} onOpenlogin={()=>setIslogin(true)} />}
            {isforgot && <ForgotModal onDismiss={() => setIsforgot(false)} onOpenlogin={()=>setIslogin(true)} />}

            <Navbar bg="dark" expand="lg" variant="dark">
                <div className='container container-theme'>
                    <Navbar.Brand href="/">
                        <img src={logo} className="logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink className="nav-link" to="/">Home</NavLink>
                            {(accountInfo == "" || accountInfo == null) || sessionStorage.getItem("username") == "" || sessionStorage.getItem("username") == undefined || sessionStorage.getItem("username") == null || !sessionStorage.getItem("username") ? 
                            <>
                           
                            </>: <>
                            <NavLink className="nav-link" to="/launchpad">Launchpad</NavLink>
                            <NavLink className="nav-link" to="/staking">Locked Staking</NavLink> </>}
                            <NavLink className="nav-link" to="/news">News</NavLink>
                            {/* <a target="blank"className="nav-link" href={settingdata && settingdata[3]?.Description} >News</a> */}

                            <NavLink className="nav-link" to="/map">Map</NavLink>

                          

                            {

                                (sessionStorage.getItem("username") == "" || sessionStorage.getItem("username") == undefined || sessionStorage.getItem("username") == null || !sessionStorage.getItem("username")) ?
                                <button className='btn getstarted1 ms-lg-3' onClick={()=>{setIslogin(true)}}>Login</button>: 
                            
                            (accountInfo == "" || accountInfo == null) ?

                            
                                <button className='btn btn getstarted ms-lg-3' onClick={() => setConnect(true)}>Connect</button>:
                                 
                                <button className='btn btn getstarted ms-lg-3' onClick={() => logOut()}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>
                                }

{

(sessionStorage.getItem("username") == "" || sessionStorage.getItem("username") == undefined || sessionStorage.getItem("username") == null || !sessionStorage.getItem("username")) ?
<button className='btn getstarted ms-lg-3' onClick={()=>{setIsregister(true)}}>Register</button>
:<></>
                               
                               }

                              <button className='btn moonlight'>
                                 {/* <button className='btn moonlight' onClick={() => themechange()}> */}
                                {/* <span className={!theme ? 'fa fa-moon' : 'fa fa-sun'} ></span> */}
                                {localStorage.getItem("theme1") == "LT" || localStorage.getItem("theme1") == undefined ?

                                <span className='fa fa-moon' onClick={()=>{themechange("DT")}} ></span>
                                 : 
                                <span className='fa fa-sun' onClick={()=>{themechange("LT")}}></span>
                                 } 
                                </button>
                           
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </div>
    )
}

export default Header;