import axios from "axios";

import isEmpty from "is-empty";

import toast, { Toaster } from 'react-hot-toast';
import {style,iconTheme,position} from './useToast';
import { backurl, frontendurl } from "../Config/env";
import { sleep } from "./useContract";


export const UseCreateUser = (param) => {
    try {
         axios
               .post(backurl + "/api/user/create", param)
               .then(async (res) => {
                   if (res.status === 200 && res.data.success === true) {
                       toast.success(`${res.data.data}`, {
                          position:position.position,
                           style:style,
                           iconTheme: iconTheme,
                       })
                       setTimeout(() => {
                           window.location.href = frontendurl;
                       },1000);
   
                   }else{
                    {
                       toast.error(`${res.data.data}`, {
                          position:position.position,
                           style:style,
                           iconTheme: iconTheme,
                       })
   
                   }}
   
               })
               .catch(err => {
   
               }
               );
   
       }
       catch (e) {
           console.log("error", e);
       } 
   
};

export const UseAdminLogin = (param) => {


    axios
        .post(backurl + "/api/validate/login", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {
               
                sessionStorage.setItem("username", res.data.username)

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
await sleep(1000)
                window.location.href = frontendurl;

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};