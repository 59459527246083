import React, { useState,useEffect } from 'react';
import {Button, Modal, InputGroup, Form} from 'react-bootstrap'
import { validateUserRegister } from '../../Hooks/useValidation';
import { UseCreateUser } from '../../Hooks/UsePostBackend';



const RegisterModal = (props) => {
    const [isregister, setIsregister] = useState(true)
    const [userName, setUserName] = useState("")
    const [Email, setEmail] = useState()
    const [Password, setPassword] = useState()
    const [Confirmpassword, setConfirmpassword] = useState("")
    const [walletaddress, setwalletaddress] = useState("")
    const [errors, seterrors] = useState({})
    const [passwordneweye, setpasswordneweye] = useState(false)
    const [passwordconfirmeye, setpasswordconfirmeye] = useState(false)




  const showNewPassword = (e) => {

      var e = document.getElementById("password");
      if (e.type === "password") {
          setpasswordneweye(true)
          e.type = "text";
      } else {
          setpasswordneweye(false)
          e.type = "password";
      }
  };

  const showConfirmPassword = (e) => {

      var e = document.getElementById("confirmPassword");
      if (e.type === "password") {
          setpasswordconfirmeye(true)
          e.type = "text";
      } else {
          setpasswordconfirmeye(false)
          e.type = "password";
      }
  };

  
  const onRegisterSubmit = async () => {
      

   

      const UserValidateData = {
          user_name: userName,
          email: Email,
          password : Password,
          confirmpassword : Confirmpassword,
          walletaddress:walletaddress.toLocaleLowerCase(),
          
      };
    
      
      const { errors, isValid } = await validateUserRegister(UserValidateData);
      if (!isValid ){
         seterrors(errors)
      }else {
        await UseCreateUser(UserValidateData)
      }
};
    return(
        <>
         <Modal show={isregister} centered size="md">
            <Modal.Header className='pb-0'>
            <h5 className='themeclr'>Register</h5>
            <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span  className='fa fa-times'></span></button>
            </Modal.Header>
            <Modal.Body className='py-4 modal_forn_new'>
              <div >
                <label>User Name</label>
            <InputGroup className="mb-3">
                <Form.Control 
                aria-label="User Name" 
                type='text' 
                required 
                value={userName}
                maxLength="20"
                pattern="^[a-z0-9_\-]+$"
                title="Only Accept Lower Case"
                onChange={(e) => { setUserName(e.target.value)}}
                placeholder='User Name'/>
            </InputGroup>
            <span className="text-danger f-12 mb-1 d-block text-left">{errors.user_name}</span>


            <label>Email</label>
              <InputGroup className="mb-3">
                <Form.Control aria-label="Email"
                 type='email' 
                 required 
                 value={Email}
                 onChange={(e) => { setEmail(e.target.value) }} 
                 placeholder='Enter Email' 
                 className='form-control'
                 />
            </InputGroup>
            <span className="text-danger f-12 mb-1 d-block text-left">{errors.email}</span>


            {/* <label>Wallet Address</label>
              <InputGroup className="mb-3">
                <Form.Control aria-label="Wallet Address" 
                type='text'
                value={walletaddress}
                onChange={(e) => { setwalletaddress(e.target.value)}} 
                placeholder='Wallet Address'/>
            </InputGroup>
            <span className="text-danger f-12 mb-1 d-block text-left">{errors.walletaddress}</span> */}


            <label>Password</label>
              <InputGroup className="mb-3">
                <Form.Control aria-label="Password" 
                type='password' 
                id="password"
                value={Password}
                onChange={(e) => { setPassword(e.target.value) }}
                placeholder='******'/>
                <InputGroup.Text >
                {!passwordneweye ?
                                    <span   className="fa fa-eye-slash pwd_hover_eye" onClick={showNewPassword}></span> :
                                    <span   className="fa fa-eye pwd_hover_eye" onClick={showNewPassword}></span>
                }

                                    </InputGroup.Text>
            </InputGroup>
            <span className="text-danger f-12 mb-1 d-block text-left">{errors.password}</span>


            <label>Confirm Password</label>
              <InputGroup className="mb-3">
                <Form.Control aria-label="Password" 
                type='password'
                id="confirmPassword"
                value={Confirmpassword}
                onChange={(e) => { setConfirmpassword(e.target.value) }}
                 placeholder='******'/>
                <InputGroup.Text >
                {!passwordconfirmeye ?
                <span   className="fa fa-eye-slash pwd_hover_eye" onClick={showConfirmPassword}></span> : 
                <span  className="fa fa-eye pwd_hover_eye" onClick={showConfirmPassword}></span>
                }

                                    </InputGroup.Text>

            </InputGroup>
            <span className="text-danger f-12 mb-1 d-block text-left">{errors.confirmpassword}</span>

            <div className='text-center'>
                <button className='btn getstarted' onClick={()=> onRegisterSubmit()}>Register</button>
            </div>
            <div className="text-center a_with_text_modal">
                <p className='text-center'>Already have an account? <a href="javascript:void(0)" className="modal_redirect_a" onClick={()=>{props.onDismiss();props.onOpenlogin()}}>Login</a></p>
            </div>
              </div>
               
            </Modal.Body>
         
        </Modal>
        </>
    )
}

export default RegisterModal